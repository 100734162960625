<script setup>
  const localePath = useLocalePath();
</script>
<template>
  <div id="top" class="xs:mt-36 sm:mt-36 md:mt-36 lg:mt-40 xl:mt-44">
    <!-- <UserGroupAlert /> -->
    <div class="xs:mx-2 sm:mx-2">
      <slot />
    </div>
    <Footer />
    <UiBackToTopButton />
    <WebshopModalCart />
    <WebshopCurrencyModal />
    <!-- <AuthForgetPasswordModal /> -->
    <!-- TODO átírni -->
    <!-- <ModalWebshopCurrencyModifiedInfo /> -->
    <!-- <ModalAuthForgetPassword /> -->
    <!-- <CookieBanner /> -->
    <LazyUiSnackBar/>
  </div>
</template>
